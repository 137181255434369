
import { mapGetters, mapActions, mapMutations } from "vuex";
import filters from "@/mixins/filters";
// import onlyFiltersPrice from "@/apollo/queries/offer/onlyFiltersPrice";

export default {
	mixins: [filters],
	data() {
		return {
			ready: false,
			inputFrom: this.filterPrice && this.filterPrice[0] ? Number(this.filterPrice[0]) : '',
			inputTo: this.filterPrice && this.filterPrice[1] ? Number(this.filterPrice[1]) : '',

			testInputPrice: 0,
			testInputTo: 0,
			masks: {

				priceInput: {
					suffix: ' ₽',
					groupSeparator: " ",
					alias: "numeric",
					autoGroup: true,
					digits: 0,
					digitsOptional: false,
					clearMaskOnLostFocus: false,
					rightAlign: false,
					max: 1000000
					// oncomplete: () => this.$emit('phoneMaskComplete'),
					// onincomplete: () => this.$emit('onincomplete'),
					// definitions: {
					// 	"#": {
					// 		validator: "[1234569]",
					// 	},
					// },
				}
			}
		}
	},
	props: {
		from: String,
		to: String,
		forFilter: {
			type: Boolean,
			default: false
		},
		firstInit:Boolean,
		filterPrice:Array
	},

	computed: {
		...mapGetters({
			filters: 'filters/filters/filters',
			chosen: 'filters/filters/chosen'
		}),
		// filterPrice() {
		// 	return this.filters?.price || null
		// },
		getPriceOptions() {
			return {
				type: 'double',
				grid: false,
				realTime: true,
				step: 1,
				from: this.inputFrom,
				to: this.inputTo,
				min: this.filterPrice?.[0],
				max: this.filterPrice?.[1],
				onChange: (event) => {
					this.inputFrom = event.from
					this.inputTo = event.to
					this.setChosen({ key: 'priceFrom', value: this.inputFrom })
					this.setChosen({ key: 'priceTo', value: this.inputTo })
				},
				// onFinish: (event) => this.forFilter && this.sendPrice(event),


			}
		}
	},
	mounted() {
		// let im = new Inputmask(this.masks.priceInput);
		// im.mask(this.$refs.inputLeft__price);
		// im.mask(this.$refs.inputRight__price);

		setTimeout(() => {
			this.changeInputs()
			this.ready = true;
		}, 900)

		// im.mask(this.$refs.inputRight__price);
	},

	methods: {
		...mapMutations({
			setCurrentCar: 'form/form-credit/SET_CURRENT_CAR',
			setChosen: 'filters/filters/SET_CHOSEN',
			setIsFilterClick: 'filters/filters/SET_IS_FILTER_CLICK',
			setTabComponent: 'modal/modal-choose/SET_TAB_COMPONENT'
		}),
		...mapActions({
			request: 'filters/filters/request',
		}),

		inp(value, tag) {
			if (tag === 'priceFrom') {
				this.inputFrom = Number(value.replace(/[^+\d]/g, ''))
				this.$refs["range-price"].slider.update({
					from: value.replace(/\s/g, '').replace('₽', '')
				})
			}
			if (tag === 'priceTo') {
				this.inputTo = Number(value.replace(/[^+\d]/g, ''))
				this.$refs["range-price"].slider.update({
					to: value.replace(/\s/g, '').replace('₽', '')
				})
			}
			let inputFrom = this.inputFrom
			let inputTo = this.inputTo
			this.setChosen({ key: 'priceFrom', value: inputFrom })
			this.setChosen({ key: 'priceTo', value: inputTo })
		},
		// check(data, type) {
		// 	const updateData = typeof data === 'number' ? data : Number(data.split(' ').join(''))
		// 	console.log(updateData)

		// 	switch (type) {
		// 		case 'mark':
		// 			this.chooseMark(updateData)
		// 			break
		// 		case 'generation':
		// 			this.chooseGeneration(updateData)
		// 			break
		// 		case 'folder':
		// 			this.chooseModel(updateData)
		// 			break
		// 		default:
		// 			break;
		// 	}

		// 	this.setChosen({ key: type, value: [data] })

		// },
		changePriceFrom(e) {
			this.inputFrom = Number(e.target.value.replace(/[^+\d]/g, ''))
			this.sendPrice()
		},
		changePriceTo(e) {
			this.inputTo = Number(e.target.value.replace(/[^+\d]/g, ''))
			this.sendPrice()
		},
		async sendPrice() {
			this.setIsFilterClick(true)
			await this.$router.push({ path: this.$route.fullPath, query: { price_from: this.inputFrom, price_to: this.inputTo } });
		},
		changeInputs() {
			if (this.$route.query.price_from && this.$route.query.price_to && this.firstInit) {
				this.inputFrom = Number(this.$route.query.price_from)
				this.inputTo = Number(this.$route.query.price_to)
				this.$emit('firstInit')
			} else {
				this.inputFrom = Number(this.filterPrice?.[0])
				this.inputTo = Number(this.filterPrice?.[1])
			}
			if(this.$route.path==='/catalog/before-1000')this.inputTo = 1000000
			this.setChosen({ key: 'priceFrom', value: this.inputFrom })
			this.setChosen({ key: 'priceTo', value: this.inputTo })

			// if(this.$route.name == 'index'){
			// 	this.onlyFilterPriceRequest({
			// 		url: '/catalog',
			// 	})
			// }
		},
		// async onlyFilterPriceRequest(assignVariables) {
		// 	try {
		// 		let response = await this.request({ query: onlyFiltersPrice, variables: assignVariables })
		// 		this.inputFrom = Number(response.data?.offerFilters?.price[0])
		// 		this.inputTo = Number(response.data?.offerFilters?.price[1])

		// 		this.setChosen({ key: 'priceFrom', value: this.inputFrom })
		// 		this.setChosen({ key: 'priceTo', value: this.inputTo })

		// 		await this.changingFilters(response.data?.offerFilters);
		// 	} catch (error) {
		// 		return this.$nuxt.error({ statusCode: 404, message: '404' })
		// 	}
		// },
		// async changingFilters(payload) {
		// 	await this.$store.commit('filters/filters/SET_FILTERS', payload)
		// 	await this.$store.commit('filters/filters/SET_ALL_CHOSEN', {})
		// },
	},
	destroyed() {
		this.changeInputs()
	}
}
